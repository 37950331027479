import React from "react"
import { Container } from "reactstrap"

const HtgfFooter: React.FC = () => {
  return (
    <footer className="main-footer bg-dark text-center text-md-left py-4">
      <Container>
        <div className="d-flex justify-content-between">
          <span className="text-muted">Powered by MoBerries</span>
          <span className="text-muted">All rights Reserved</span>
        </div>
      </Container>
    </footer>
  )
}

export default HtgfFooter
